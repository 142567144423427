<template>
  <div>
    <v-app id="inspire">
      <!-- v-dialog baslıyor -->
      <v-dialog v-model="dialog" max-width="500px" persistent scrollable>
        <v-card 
        class="elevation-5"
        >
          <v-card-title>
            <span class="headline">Hasta Ekle</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="isFormValid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="selected.name" required label="Hasta Adı Baş Harfi" filled maxlength="1"
                      :rules="rules.firstCharacter"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="selected.surname" required label="Hasta Soyadı Baş Harfi" filled
                      maxlength="1" :rules="rules.firstCharacter"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                      :return-value.sync="selected.SignatureDate" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :value="getDateFormat(selected.SignatureDate)" label="BGOF İmza Tarihi"
                          append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" filled>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="selected.SignatureDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"  class="pr-10">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(selected.SignatureDate)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-select v-model="selected.researcherid" :items="userInfoList" item-text="fullName"
                      item-value="userId" filled label="Sorumlu Araştırmacı" required :rules="rules.name2"></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="3 ml-auto">
                <button class="btn btn-secondary" @click="closeDialog">
                  İptal
                </button>
              </v-col>
              <v-col cols="4">
                <button class="btn btn-primary" id="btncreatePatient" @click="createPatient" :disabled="!isFormValid">
                  Ekle
                </button>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- v-dialog bitti -->
      <!-- düzenle dialog baslıyor -->
      <v-dialog v-model="dialog2" max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">Rol Seç</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select v-model="roleidUpdate" append-icon="search" label="Rol Seç" single-line hide-details
                    :items="getworkmemberRole" item-text="value" item-value="id"></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col cols="3 ml-auto">
                <button class="btn btn-secondary" @click="dialog2 = false">
                  İptal
                </button>
              </v-col>
              <v-col cols="4">
                <button class="btn btn-primary" @click="updateRole">
                  Güncelle
                </button>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- düzenle dialog end -->
      <!-- hasta düzenle dialog -->
      <v-dialog v-model="dialog3" max-width="500px" persistent scrollable>
        <v-card class="elevation-5">
          <v-card-title>
            <span class="headline">Hasta Güncelle</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="isFormValid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="selected3.name" required label="Hasta Adı Baş Harfi" filled maxlength="1"
                      :rules="rules.firstCharacter"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="selected3.surname" required label="Hasta Soyadı Baş Harfi" filled
                      maxlength="1" :rules="rules.firstCharacter"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false"
                      :return-value.sync="selected3.SignatureDate" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :value="getDateFormat(selected3.SignatureDate)" label="BGOF İmza Tarihi"
                          append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" filled>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="selected3.SignatureDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu4 = false"  class="pr-10">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu4.save(selected3.SignatureDate)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-select v-model="selected3.researcherid" :items="userInfoList" item-text="fullName"
                      item-value="userId" filled label="Sorumlu Araştırmacı" required :rules="rules.name2"></v-select>
                  </v-col>
                  <v-col cols="12" v-if="isShowGlobalAdmin">
                    <v-text-field v-model="selected3.sessionId" required label="Hasta Session Id" filled
                      ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="3 ml-auto">
                <button class="btn btn-secondary" @click="dialog3 = false">
                  İptal
                </button>
              </v-col>
              <v-col cols="4">
                <button class="btn btn-primary" @click="updatePatientInfo" v-if="isFormValid">
                  Güncelle
                </button>
              </v-col>
              <v-col  v-if="isShowGlobalAdmin" cols="4">
                <button class="btn btn-primary" @click="openDeleteUserDialog(selected3.id)">
                  Hastayı Sil
                </button>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- hasta düzenle dialog end -->

      <!-- loading dialog -->
      <v-dialog v-model="loading" persistent :width="width">
        <v-card :color="color" :light="light" :dark="dark">
          <v-card-text>
            <v-card-title> {{ msg }}</v-card-title>
            <v-progress-linear :value="percentComplete" :indeterminate="indeterminate" :color="barColor" class="mb-0">
            </v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- loading dialog end -->
      
      <v-dialog v-model="dialogiframe" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
        <v-card>
          <v-toolbar flat dark color="#5f5293" max-height="64px">
            <v-btn icon dark @click="turnBack">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ visitName }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text>
            <iframe :src="iframeLink" title="W3Schools Free Online Web Tutorials" style="height: 100%; width: 100%">
            </iframe>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-container>
        <v-row>
          <v-col md="12" sm="12" xs="12">
            <v-card class="elevation-5 px-3">
              <v-row>
                <v-col xs="12" style="padding-bottom: 0px !important">
                  <v-card-title class="v-card__title_nocard px-0 mx-0">
                    {{ this.workShortName }}  ({{ getDateFormat(this.createDate) }} - {{ getDateFormat(this.completionDate) }})
                  </v-card-title>
                  <v-card-text class="cardText">
                    {{ this.workName }}
                  </v-card-text>
                  <v-card-text class="cardText">
                    <b>Protokol No: </b> {{ this.protocolNumber }}
                  </v-card-text>
                  <v-card-text class="cardText">
                  <b> SAK: </b>{{ sak }}
                  </v-card-text>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="2">
                  <v-card class="elevation-5_nocard" style="
                      min-height: 170px !important;
                      max-height: 170px !important;
                    ">
                    <v-card-title class="workDetailCards">
                     Planlanan Hasta
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Toplam</div>

                          <div class="change changeColorYellow">
                            {{ this.workInfoList.numberOfPatients }}
                          </div>
                        </v-col>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Merkez</div>

                          <div class="change">80</div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col xs="2">
                  <v-card class="elevation-5_nocard" style="
                      min-height: 170px !important;
                      max-height: 170px !important;
                    ">
                    <v-card-title class="workDetailCards"  >
                      BGOF Alınan Hasta
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Toplam</div>

                          <div class="change changeColorYellow">
                            {{ this.workInfoList.bgofFilled }}
                          </div>
                        </v-col>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Merkez</div>

                          <div class="change">
                            {{ this.addWorkInfo.bgofPatientCenter }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col xs="2">
                  <v-card class="elevation-5_nocard" style="
                      min-height: 170px !important;
                      max-height: 170px !important;
                    ">
                    <v-card-title class="workDetailCards">
                      Taranmış Tanı Alan Hasta
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Toplam</div>

                          <div class="change changeColorYellow">
                            {{ this.workInfoList.diagnosedPatient }}
                          </div>
                        </v-col>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Merkez</div>
                          <div class="change">
                            {{ this.addWorkInfo.diagnosedPatientCenter }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col xs="2">
                  <v-card class="elevation-5_nocard" style="
                      min-height: 170px !important;
                      max-height: 170px !important;
                    ">
                    <v-card-title class="workDetailCards">
                      Taranmış Tanı Alamamış Hasta
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Toplam</div>

                          <div class="change changeColorYellow">
                            {{ this.workInfoList.undiagnosedPatient }}
                          </div>
                        </v-col>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Merkez</div>

                          <div class="change">
                            {{ this.addWorkInfo.undiagnosedPatientCenter }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col xs="2">
                  <v-card class="elevation-5_nocard" style="
                      min-height: 170px !important;
                      max-height: 170px !important;
                    ">
                    <v-card-title class="workDetailCards" style="word-break: break-word; display: inline-table">
                      Tarama Süreci Devam Eden
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Toplam</div>
                          <div class="change changeColorYellow">
                            {{ this.workInfoList.onScanningPatient }}
                          </div>
                        </v-col>
                        <v-col xs="6" style="padding: 0px">
                          <div style="text-align: center">Merkez</div>

                          <div class="change">
                            {{ this.addWorkInfo.onScanningPatientCenter }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-9">
          <v-col lg="12" md="12" sm="12" xs="12">
            <v-tabs grow v-model="tab">
              <v-tab href="#hastalar"> Gönüllüler </v-tab>
              <v-tab href="#calismadosyalari"> Çalışma Dosyalari </v-tab>
              <v-tab href="#vizitler" v-if="isShowGlobalAdmin">
                VİZİTLER
              </v-tab>
              <v-tab href="#calismaekibi"> ÇALIŞMA EKİBİ </v-tab>
              <v-tab href="#calismadetayi" v-if="isShowGlobalAdmin">Çalışma Detayı
              </v-tab>
              <v-tab href="#vizittakip"> Vizit Takip </v-tab>
            </v-tabs>
            <v-tabs-items :value="tab">
              <v-tab-item value="hastalar">
                <v-container>
                  <v-row>
                    <v-col>
                      <div class="form-group">
                        <v-row>
                          <v-col xl="3" lg="6" md="6">
                            <v-text-field v-model="search2" append-icon="search" label="Ara" single-line hide-details
                              style="
                                margin-bottom: 15px !important;
                                padding-top: 10px !important;
                              "></v-text-field>
                          </v-col>
                          <!-- <v-col cols="4"></v-col> -->

                          <v-col xl="2" lg="2" md="4" sm="12" xs="12" class="ml-auto">
                            <button type="button" v-if="(showAuthority == 1 || isShowGlobalAdmin)&&this.selected2.isActive" class="btn btn-primary"
                              @click.stop="dialog = true">
                              Hasta Ekle
                            </button>
                          </v-col>
                        </v-row>
                      </div>
                      <v-row>
                        <v-col lg="12" md="12" sm="12" xs="12">
                          <v-data-table no-data-text="Liste Boş" :headers="
                            showAuthority == 2 || showAuthority == 0
                              ? headers3
                              : headers2
                              " :items="patientListShow" :search="search2" :footer-props="{
                              'items-per-page-options': [20, 30, 40, -1],
                            }" :items-per-page="20">
                            <template v-slot:[`item.name`]="{ item }">
                              <div>
                                {{ item.name }}
                              </div>
                            </template>
                            <template v-slot:[`item.researcherInfo`]="{ item }">
                              <div>
                                {{item.fullName}}
                              </div>
                              <div>
                                {{item.researcherRole}}
                              </div>
                            </template> 
                            <template v-slot:[`item.lock`]="{ item }">
                              <v-icon class="fas fa-lock fa-locked" v-if="item.lock"></v-icon>
                              <v-icon class="fas fa-lock-open fa-locked" v-if="!item.lock"></v-icon>
                              <v-icon class="fas fa-check-square fa-custom-signature" v-if="item.signature"></v-icon>
                              <v-icon class="far fa-square fa-custom-signature" v-if="!item.signature"></v-icon>
                            </template>
                            

                            <template v-slot:[`item.action`]="{ item }">
                              <v-tooltip top>
                                 <template v-slot:activator="{ on, attrs }">
                                    <router-link :to="{ name: 'patientdetails', params: { id: item.id } }">
                                        <v-icon v-bind="attrs"
                                              v-on="on"
                                              class="
                                            fas
                                            fa-chevron-circle-right
                                            text-warning
                                            " @click="getPatientId(item)">
                                            
                                          </v-icon>
                                    </router-link>
                                  </template>
                                  <span>Detay</span>
                              </v-tooltip>
                              <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs"
                                              v-on="on" class="fa-pencil-blue" @click="updatePatient(item)">
                                fas fa-pencil-alt
                              </v-icon>
                                  </template>
                                  <span>Düzenle</span>
                              </v-tooltip>
                              
                            </template>
                            
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="calismadosyalari">
                <v-container>
                  <v-form v-model="isFormValid">
                    <v-row v-if="showAuthority == 2 || isShowGlobalAdmin">
                      <v-col xl="3" lg="6" md="6">
                        <v-text-field v-model="fileNameSend" required label="Eklemek İstediğiniz Dosya İsmi (*)" filled
                          :rules="rules.name"
                          @change="changeName"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row v-if="showAuthority == 2 || isShowGlobalAdmin">
                    <v-col xl="3" lg="6" md="6">
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-file-input ref="fileupload" filled :rules="[
                              (value) =>
                                !value ||
                                value.size < 10000000 ||
                                'Dosya en fazla 10 MB olmalı.',
                            ]" :placeholder="'Dosya Seçiniz'" prepend-icon="mdi-file" :label="'Dosya Seçiniz'"
                              @change="changeFile" show-size></v-file-input>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col xl="1 mr-auto" lg="2 mr-auto" md="2 mr-auto" style="padding: 0px">
                      <button type="button" class="btn btn-primary" @click="addFileToTable" :disabled="!isFormValid">
                        Yükle
                      </button>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-data-table :headers="
                        (showAuthority == 0 || showAuthority == 1) &&
                        !globaladmin
                          ? fileNameTable2
                          : fileNameTable
                      " :items="fileTableItems" :footer-props="{
                          'items-per-page-options': [20, 30, 40, -1],
                        }" :items-per-page="20">
                        <template v-slot:[`item.download`]="{ item }">
                          <v-icon class="text-warning pr-1" @click="downloadIt(item)">
                            fas fa-download
                          </v-icon>
                        </template>
                        <template v-slot:[`item.delete`]="{ item }">
                          <v-icon class="text-danger" @click="deleteItemFromFileTable(item)">
                            fas fa-minus-circle
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="vizitler">
                <v-container>
                  <v-row>
                    <v-col md="12">
                      <div class="form-group" v-for="(item, index) in visitFile" :key="index">
                        <v-row>
                          <v-col xl="3" md="4">
                            <v-text-field :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " filled v-model="item.visitName" :name="`visitFile[${index}][visitName]`"
                              label="Vizit Adı">
                            </v-text-field>
                          </v-col>

                          <v-col xl="1" md="2" v-if="showAuthority == 2 || globaladmin">
                            <v-form v-model="vizitValid">
                              <v-text-field :disabled="
                                showAuthority == 1 ||
                                showAuthority == 0 ||
                                workInfoList.lock === true ||
                                workInfoList.archive === true
                              " filled v-model="item.pageId" :name="`visitFile[${index}][pageId]`" label="Page İd"
                                :rules="rules.name2" maxlength="4">
                              </v-text-field>
                            </v-form>
                          </v-col>

                          <v-col xl="2" md="3">
                            <v-menu :ref="'element' + item.id" v-model="item.menu3" :close-on-content-click="false"
                              :return-value.sync="item.plannedDate" transition="scale-transition" offset-y
                              min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field :disabled="
                                  showAuthority == 1 ||
                                  showAuthority == 0 ||
                                  workInfoList.lock === true ||
                                  workInfoList.archive === true
                                " :name="`visitFile[${index}][plannedDate]`" :value="getDateFormat(item.plannedDate)"
                                  label="Planlanan  Tamamlanma Tarihi" append-icon="mdi-calendar" readonly
                                  v-bind="attrs" v-on="on" filled>
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="item.plannedDate" no-title scrollable :allowed-dates="
                                (date) =>
                                  date <=
                                  new Date().toISOString().substr(0, 10)
                              ">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="item.menu3 = false" class="pr-10">
                                  Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="abc('element' + item.id, item)">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col xl="1" md="2" style="padding: 0px">
                            <button type="button" v-if="
                              showAuthority == 2 ||
                              (isShowGlobalAdmin &&
                                (workInfoList.lock === false ||
                                  workInfoList.lock === null) &&
                                (workInfoList.archive === false ||
                                  workInfoList.archive === null))
                            " class="btn btn-primary" @click="deleteVisit(index)">
                              Sil
                            </button>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col md="3">
                      <div class="form-group">
                        <v-text-field v-if="
                          showAuthority == 2 ||
                          (isShowGlobalAdmin &&
                            (workInfoList.lock === false ||
                              workInfoList.lock === null) &&
                            (workInfoList.archive === false ||
                              workInfoList.archive === null))
                        " required label="Eklemek İstediğiniz Vizit Sayısı" filled v-model="count"></v-text-field>
                      </div>
                    </v-col>

                    <v-col md="1" style="padding: 0px">
                      <div class="form-group">
                        <button type="button" v-if="
                          showAuthority == 2 ||
                          (isShowGlobalAdmin &&
                            (workInfoList.lock === false ||
                              workInfoList.lock === null) &&
                            (workInfoList.archive === false ||
                              workInfoList.archive === null))
                        " class="btn btn-primary" @click="createVisitDetails()">
                          Ekle
                        </button>
                      </div>
                    </v-col>
                    <v-col md="2 ml-auto">
                      <div class="form-group">
                        <button type="button" v-if="
                          showAuthority == 2 ||
                          (isShowGlobalAdmin &&
                            (workInfoList.lock === false ||
                              workInfoList.lock === null) &&
                            (workInfoList.archive === false ||
                              workInfoList.archive === null))
                        " class="btn btn-primary" @click="addVisit" :disabled="!vizitValid || !vizitChanged"
                        id="btnSave">
                          Kaydet
                        </button>
                      </div>
                      <div class="form-group">
                        <button type="button" v-if="
                          showAuthority == 2 ||
                          (isShowGlobalAdmin &&
                            (workInfoList.lock === false ||
                              workInfoList.lock === null) &&
                            (workInfoList.archive === false ||
                              workInfoList.archive === null))
                        " class="btn btn-primary" @click="updateVisit"
                        id="btnUpdate">
                          Güncelle
                        </button>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="calismaekibi">
                <v-row  v-if="
                  isShowGlobalAdmin ||
                  ((workInfoList.lock === false ||
                    workInfoList.lock === null) &&
                    (workInfoList.archive === false ||
                      workInfoList.archive === null))
                ">
                  <v-col align-self="start">
                    <v-select v-if="showAuthority == 2 || isShowGlobalAdmin" v-model="search" label="Kullanıcı Seç"
                      single-line hide-details :items="getworkmemberList"
                      :item-text="(item) => `${item.name}  ${item.surname}`" item-value="id" style="
                        margin-bottom: 15px !important;
                        padding-top: 10px !important;
                      "></v-select>
                  </v-col>
                  <v-col md="4">
                    <v-select v-if="showAuthority == 2 || isShowGlobalAdmin" v-model="roleid" label="Rol Seç"
                      single-line hide-details :items="getworkmemberRole" item-text="value" item-value="id" style="
                        margin-bottom: 15px !important;
                        padding-top: 10px !important;
                      "></v-select>
                  </v-col>
                  <v-col xl="2" md="4">
                    <button type="button" v-if="showAuthority == 2 || isShowGlobalAdmin" class="btn btn-primary"
                      @click="addWorkMember">
                      Kullanıcı Ata
                    </button>
                  </v-col>
                  
                  <v-col align-self="start" cols="12">
                    <v-text-field v-model="search3" append-icon="search" label="Ara" single-line hide-details style="
                        margin-bottom: 15px !important;
                        padding-top: 10px !important;
                      "></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table :headers="
                      (showAuthority == 0 || showAuthority == 1) &&
                      !globaladmin
                        ? headers4
                        : headers
                    " :items="desserts2" no-data-text="Liste Boş" :search="search3">
                      <template v-slot:[`item.researcherInfo`]="{ item }">
                        <div>
                          {{item.fullName}}
                        </div>
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-icon class="text-warning" @click="getCurrentid(item)">
                          fas fa-chevron-circle-right
                        </v-icon>
                      </template>
                      <template v-slot:[`item.edit`]="{ item }">
                        <v-icon class="fa-pencil-blue" @click="getRoleUpdateInfo(item)" v-if="
                          showAuthority == 2 ||
                          (isShowGlobalAdmin &&
                            (workInfoList.lock === false ||
                              workInfoList.lock === null) &&
                            (workInfoList.archive === false ||
                              workInfoList.archive === null))
                        ">
                          fas fa-pencil-alt
                        </v-icon>
                      </template>
                      <template v-slot:[`item.delete`]="{ item }">
                        <v-icon class="text-danger" @click="deleteMember(item)" v-if="
                          showAuthority == 2 ||
                          ((workInfoList.lock === false ||
                            workInfoList.lock === null) &&
                            (workInfoList.archive === false ||
                              workInfoList.archive === null))
                        ">
                          fas fa-minus-circle
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="calismadetayi" v-if="showAuthority == 2 || isShowGlobalAdmin">
                <v-row>
                  <v-col md="6">
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.workShortName" required label="Çalışmanın Kısa İsmi" filled
                            :rules="rules.name"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.workName" required label="Çalışmanın Açık İsmi" filled
                            :rules="rules.name"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>

                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-menu :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " ref="menu" v-model="menu" :close-on-content-click="false"
                            :return-value.sync="selected.createDate" transition="scale-transition" offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :disabled="
                                workInfoList.lock === true ||
                                workInfoList.archive === true
                              " :value="getDateFormat(selected2.createDate)" label="Başlangıç Tarihi"
                                append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" filled>
                              </v-text-field>
                            </template>
                            <v-date-picker v-model="selected2.createDate" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false" class="pr-10">
                                Cancel
                              </v-btn>
                              <v-btn text color="primary" @click="$refs.menu.save(selected.createDate)">
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " type="number" v-model="selected2.visitInterval" required
                            label="Planlanan Vizit Aralığı(Gün)" filled :rules="rules.number"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-select :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.researchType" label=" Araştırmanın Tipi" filled
                            :items="researchTypeItems" item-text="text" item-value="value"></v-select>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-select :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.researchProduct" label=" Araştırma Ürünü" filled
                            :items="researchProductItems" item-text="text" item-value="value"
                            @change="changeResearchSubType"></v-select>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-select :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.researchSubType" label=" Araştırma Alt Tipi" filled
                            :items="researchSubTypeItems" item-text="text" item-value="value"></v-select>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.approachingCount" required label="Yaklaşıyor Uyarısı" filled
                            :rules="rules.number"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.laggingCount" required label="Gecikiyor (Sarı Uyarı)" filled
                            :rules="rules.number"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col lg="4">
                          <v-checkbox v-model="selected2.isSignatureRequired" :label="`İmza Zorunluluğu`"></v-checkbox>
                        </v-col>
                        <v-col lg="4">
                          <v-switch v-model="selected2.isActive" flat :label="`Hasta Alımına ${selected2.isActive==true?'Açık':'Kapalı'}`"></v-switch>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col md="6">
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.surveyId" required label="Survey ID" filled :rules="rules.name">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.protocolNumber" required label="Protokol Numarası" filled
                            :rules="rules.name"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-menu :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " ref="menu2" v-model="menu2" :close-on-content-click="false"
                            :return-value.sync="selected.completionDate" transition="scale-transition" offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :disabled="
                                workInfoList.lock === true ||
                                workInfoList.archive === true
                              " :value="getDateFormat(selected2.completionDate)" label="Tamamlanma Tarihi"
                                append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" filled>
                              </v-text-field>
                            </template>
                            <v-date-picker v-model="selected2.completionDate" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu2 = false" class="pr-10">
                                Cancel
                              </v-btn>
                              <v-btn text color="primary" @click="
                                $refs.menu2.save(selected.completionDate)
                              ">
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.numberOfPatients" required label="Planlanan Tahmini Hasta Sayısı"
                            filled :rules="rules.number"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>

                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-select :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.status" label=" Durum/Statü" filled :items="statusItems"
                            item-text="text" item-value="value"></v-select>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-select :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " :items="responsibleCroitems" v-model="selected2.responsibleCro"
                            item-text="organizationName" item-value="id" label=" Sorumlu CRO" filled></v-select>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-select :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " :items="supportersitem" item-text="organizationName" item-value="organizationName"
                            v-model="selected2.support" label=" Destekleyici" filled></v-select>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.delayedCount" required label="Gecikti (Kırmızı Uyarı)" filled
                            :rules="rules.number"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="form-group">
                      <v-row>
                        <v-col>
                          <v-text-field :disabled="
                            workInfoList.lock === true ||
                            workInfoList.archive === true
                          " v-model="selected2.notHappenedCount" required label="Gerçekleşmedi Uyarısı" filled
                            :rules="rules.number"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="2 m-auto">
                    <button type="button" v-if="
                      showAuthority == 2 ||
                      (isShowGlobalAdmin &&
                        (workInfoList.lock === false ||
                          workInfoList.lock === null) &&
                        (workInfoList.archive === false ||
                          workInfoList.archive === null))
                    " class="btn btn-primary" @click="updateWorkDetails">
                      Güncelle
                    </button>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-row v-if="!isShowGlobalAdmin">
                <v-tab-item value="calismadetayi" v-if="showAuthority == 1 || showAuthority == 0">
                  <v-row>
                    <v-col md="6">
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-text-field v-model="selected2.workShortName" required label="Çalışmanın Kısa İsmi" filled
                              :disabled="
                                showAuthority == 1 ||
                                showAuthority == 0 ||
                                workInfoList.lock === true ||
                                workInfoList.archive === true
                              " :rules="rules.name"></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-text-field v-model="selected2.workName" required label="Çalışmanın Açık İsmi" filled
                              :disabled="
                                showAuthority == 1 ||
                                showAuthority == 0 ||
                                workInfoList.lock === true ||
                                workInfoList.archive === true
                              " :rules="rules.name"></v-text-field>
                          </v-col>
                        </v-row>
                      </div>

                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-menu :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " ref="menu" v-model="menu" :close-on-content-click="false"
                              :return-value.sync="selected.createDate" transition="scale-transition" offset-y
                              min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field :disabled="
                                  showAuthority == 1 ||
                                  showAuthority == 0 ||
                                  workInfoList.lock === true ||
                                  workInfoList.archive === true
                                " v-model="selected2.createDate" label="Başlangıç Tarihi" append-icon="mdi-calendar"
                                  readonly v-bind="attrs" v-on="on" filled>
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="selected2.createDate" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false" class="pr-10">
                                  Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(selected.createDate)">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-text-field :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " v-model="selected2.visitInterval" required label="Planlanan Vizit Aralığı(Gün)" filled
                              readonly :rules="rules.name"></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-select :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " v-model="selected2.researchType" label=" Araştırmanın Tipi" filled
                              :items="researchTypeItems" item-text="text" item-value="value"></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-select v-model="selected2.researchProduct" label=" Araştırma Ürünü" filled :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " :items="researchProductItems" item-text="text" item-value="value"
                              @change="changeResearchSubType"></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-select v-model="selected2.researchSubType" label=" Araştırma Alt Tipi" filled :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " :items="researchSubTypeItems" item-text="text" item-value="value"></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col lg="4">
                            <v-checkbox v-model="selected2.isSignatureRequired" :label="`İmza Zorunluluğu`" disabled>
                            </v-checkbox>
                          </v-col>
                          <v-col lg="4">
                            <v-switch v-model="selected2.isActive" flat :label="`Hasta Alımı {${selected2.isActive==true?'Açık':'Kapalı'}}`"></v-switch>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col md="6">
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-text-field :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " v-model="selected2.surveyId" required label="Survey ID" filled readonly
                              :rules="rules.name"></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-text-field v-model="selected2.protocolNumber" required label="Protokol Numarası" filled
                              :disabled="
                                showAuthority == 1 ||
                                showAuthority == 0 ||
                                workInfoList.lock === true ||
                                workInfoList.archive === true
                              " :rules="rules.name"></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-menu :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " ref="menu2" v-model="menu2" :close-on-content-click="false"
                              :return-value.sync="selected.completionDate" transition="scale-transition" offset-y
                              min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field :disabled="
                                  showAuthority == 1 ||
                                  showAuthority == 0 ||
                                  workInfoList.lock === true ||
                                  workInfoList.archive === true
                                " v-model="selected2.completionDate" label="Tamamlanma Tarihi"
                                  append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" filled>
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="selected2.completionDate" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu2 = false" class="pr-10">
                                  Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="
                                  $refs.menu2.save(selected.completionDate)
                                ">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-text-field v-model="selected2.numberOfPatients" required
                              label="Planlanan Tahmini Hasta Sayısı" filled :disabled="
                                showAuthority == 1 ||
                                showAuthority == 0 ||
                                workInfoList.lock === true ||
                                workInfoList.archive === true
                              " :rules="rules.number"></v-text-field>
                          </v-col>
                        </v-row>
                      </div>

                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-select v-model="selected2.status" label=" Durum/Statü" filled :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " :items="statusItems" item-text="text" item-value="value"></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-select :items="responsibleCroitems" v-model="selected2.responsibleCro" :disabled="
                              showAuthority == 1 ||
                              showAuthority == 0 ||
                              workInfoList.lock === true ||
                              workInfoList.archive === true
                            " item-text="organizationName" item-value="id" label=" Sorumlu CRO" filled></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="form-group">
                        <v-row>
                          <v-col>
                            <v-select :items="supportersitem" item-text="organizationName" item-value="organizationName"
                              :disabled="
                                showAuthority == 1 ||
                                showAuthority == 0 ||
                                workInfoList.lock === true ||
                                workInfoList.archive === true
                              " v-model="selected2.support" label=" Destekleyici" filled></v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2 m-auto">
                      <button type="button" v-if="
                        showAuthority == 2 ||
                        (isShowGlobalAdmin &&
                          (workInfoList.lock === false ||
                            workInfoList.lock === null) &&
                          (workInfoList.archive === false ||
                            workInfoList.archive === null))
                      " class="btn btn-primary" @click="updateWorkDetails">
                        GÜNCELLE
                      </button>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-row>

              <v-tab-item value="vizittakip">
                <v-container>
                  <v-row>
                    <v-col lg="12" md="12" sm="12" xs="12">
                      <v-data-table no-data-text="Liste Boş" :headers="headersVisitTable" :items="workVisitTable"
                        :search="search2" :footer-props="{
                          'items-per-page-options': [20, 30, 40, -1],
                        }" :items-per-page="20">
                        <template v-slot:[`item.name`]="{ item }">
                          <div>
                            {{ item.patientName }}
                          </div>
                        </template>

                        <template v-slot:[`item.visitStatus`]="{ item }">
                          <div >
                            <v-container v-if="item.visitStatus == 'Yaklaşıyor'">
                              <div style="background-color: yellow; color: black;">
                                {{ item.visitStatus }}
                              </div>
                            </v-container>

                            <v-container v-if="item.visitStatus == 'Vizit günü'">
                              <div style="background-color: #00ff1a;color: black;">
                                {{ item.visitStatus }}
                              </div>
                            </v-container>

                            <v-container v-if="item.visitStatus == 'Gecikiyor'">
                              <div style="background-color: orange;color: black;">
                                {{ item.visitStatus }}
                              </div>
                            </v-container>

                            <v-container v-if="item.visitStatus == 'Gecikti'">
                              <div style="background-color: #Ff4b4b;color: black;">
                                {{ item.visitStatus }}
                              </div>
                            </v-container>

                            <v-container v-if="item.visitStatus == 'Gerçekleşmedi'">
                              <div style="background-color: #AFB0B0;color: black;">
                                {{ item.visitStatus }}
                              </div>
                            </v-container>
                          </div>
                        </template>

                        <template v-slot:[`item.visitPlannedDate`]="{ item }">
                          <div style="
                        margin-bottom: 15px !important;
                        padding-top: 10px !important;
                      ">
                            {{ getDateFormat(item.visitPlannedDate) }}
                          </div>
                        </template>
                        <template v-if="showAuthority == 2 || isShowGlobalAdmin" v-slot:[`item.centerName`]="{ item }">
                          <div>
                            {{ item.centerName }}
                          </div>
                        </template>
                        <template v-if="showAuthority == 2 || isShowGlobalAdmin" v-slot:[`item.researcherName`]="{ item }">
                          <div>
                            {{ item.researcherName }}
                          </div>
                        </template>
                        <template v-slot:[`item.Id`]="{ item }">
                              <v-tooltip top>
                                 <template v-slot:activator="{ on, attrs }">
                                    <router-link :to="{ name: 'patientdetails', params: { id: item.id } }">
                                        <v-icon v-bind="attrs"
                                              v-on="on"
                                              class="
                                            fas
                                            fa-chevron-circle-right
                                            text-warning
                                            " @click="getPatientId(item)">
                                            
                                          </v-icon>
                                    </router-link>
                                  </template>
                                  <span>Git</span>
                              </v-tooltip>
                              
                            </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
.change {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-top: 3px;
}

.changeColorYellow {
  color: #f5ba87 !important;
}

.v-input--selection-controls .v-input__slot>.v-label {
  margin: 0px !important;
  font-size: 1rem !important;
}

@import "@/global.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  SET_SELECTEDPAGEITEMNAME,
  REMOVE_SELECTEDPAGEITEMNAME,
  REMOVE_FROMWORKLIST,
} from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
//import Button from './vue-bootstrap/Button.vue';
import axios from "axios";
//import UsersView from "./UsersView.vue";
export default {
  //components: { Button },
  name: "worksdetails",
  data() {
    return {
      iframeLink: "",
      fileNameSend: "",
      search3: "",
      vizitChanged:false,
      vizitValid: false,
      isFormValid: false,
      fileSelected: "",
      isShowGlobalAdmin: false,
      globaladmin: false,
      cro: false,
      user: false,
      isShow: false,
      showAuthority: null,
      menu2: false,
      roleid: "",
      roleidUpdate: "",
      getworkmemberList: [],
      getworkmemberRole: [],
      rules: {
        name: [(val) => (val || "").length > 0 || "Bu alan doldurulmalı"],
        name2: [(val) => !!val || "Bu alan doldurulmalı"],

        number: [
          (v) => !!v || "Bu alan doldurulmalı",
          (v) => (v && v >= 0) || "Sadece pozitif sayılar girilebilir"],

        firstCharacter: [
          (v) => {
            var regex = /[a-zA-ZğüşöçİıĞÜŞÖÇ\-0-9]{1}/;
            return regex.test(v) || "Geçersiz Karakter";
          },
        ],
      },
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      menu4: false,
      array: [],
      workID: "",
      userInfoList: [],
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialogiframe: false,
      search: null,
      search2: null,
      desserts: [],
      desserts2: [],
      headers: [
        {
          text: "Ünvan",
          align: "center",
          value: "user.title",
          width: "120px",
        },
        //{ text: "Kullanıcı", value: "user.username" },
        { text: "Ad Soyad",align: "center", value: "fullName", width: "150px" },
        //{ text: "Soyad", value: "user.surname", width: "130px" },
        { text: "Merkez/Org",align: "center", value: "user.center.centerName", width: "150px" },
        { text: "Bölüm",align: "center", value: "user.specialtyDepartment", width: "100px" },
        { text: "Rol",align: "center", value: "role" },
        //{ text: "E-posta", value: "user.email" },
        //{ text: "Telefon", value: "user.phoneNumber" },
        //{ text: "Şehir", value: "user.city", width: "100px" },
        { text: "Ülke",align: "center", value: "user.country", width: "100px" },
        { text: "Detay",align: "center", value: "action", sortable: false, width: "70px" },
        { text: "Düzenle",align: "center", value: "edit", sortable: false, width: "70px" },
        { text: "Sil",align: "center", value: "delete", sortable: false, width: "70px" },
      ],
      headers4: [
        {
          text: "Ünvan",
          align: "center",
          value: "user.title",
          width: "120px",
        },
        //{ text: "Kullanıcı", value: "user.username" },
        { text: "Ad Soyad",align: "center", value: "fullName", width: "150px" },
        //{ text: "Soyad", value: "user.surname", width: "130px" },
        { text: "Merkez/Org",align: "center", value: "user.center.centerName", width: "150px" },
        { text: "Bölüm",align: "center", value: "user.specialtyDepartment", width: "100px" },
        { text: "Rol",align: "center", value: "role" },
        //{ text: "E-posta", value: "user.email" },
        //{ text: "Telefon", value: "user.phoneNumber" },
        //{ text: "Şehir", value: "user.city", width: "100px" },
        { text: "Ülke",align: "center", value: "user.country", width: "100px" },
        { text: "Detay",align: "center", value: "action", sortable: false, width: "70px" },
      ],
      headers2: [
        {
          text: "Hasta No",
          align: "center",
          value: "patientNo",
        },
        { text: "Hasta İsmi",align: "center", value: "name" },
        { text: "Merkez",align: "center", value: "researcher.center.centerName" },
        { text: "Bölüm",align: "center", value: "researcher.specialtyDepartment" },
        { text: "Araştırmacı / Rolü",align: "center", value: 'researcherInfo' },
        { text: "Durum",align: "center", value: "status" },
        { text: "Advers Olay",align: "center", value: "adversolay" },
        { text: "Kilit"+"\n"+"İmza",align: "center", value: "lock", sortable: false },
        { text: "İşlemler",align: "center", value: "action", sortable: false }
      ],
      headers3: [
        {
          text: "Hasta No",
          align: "center",
          value: "patientNo",
        },
        { text: "Hasta İsmi",align: "center", value: "name" },
        { text: "Merkez",align: "center", value: "researcher.center.centerName" },
        { text: "Bölüm",align: "center", value: "researcher.specialtyDepartment" },
        { text: "Araştırmacı / Rolü",align: "center", value: "researcherInfo" },
        { text: "Durum",align: "center", value: "status" },
        { text: "Advers Olay",align: "center", value: "adversolay" },
        { text: "Kilit"+"\n"+"İmza",align: "center", value: "lock", sortable: false },
        { text: "İşlemler",align: "center", value: "action", sortable: false },
      ],
      headersVisitTable: [
        {
          text: "Hasta No",
          align: "center",
          value: "patientNo",
        },
        { text: "Hasta İsmi",align: "center", value: "patientName" },
        { text: "İlgili Vizit",align: "center", value: "visitName" },
        { text: "Vizit Durumu",align: "center", value: "visitStatus" },
        { text: "Planlanan Vizit Tarihi",align: "center", value: "visitPlannedDate" }
      ],
      selected: {
        name: "",
        workid: "",
        surname: "",
        SignatureDate: new Date().toISOString().substr(0, 10),
        researcherid: "",
      },
      selected2: {
        workName: "",
        isActive:false,
        Id:"",
        workShortName: "",
        responsibleCro: "",
        protocolNumber: "",
        numberOfPatients: "",
        visitInterval: "",
        createDate: new Date().toISOString().substr(0, 10),
        completionDate: new Date().toISOString().substr(0, 10),
        researchType: "",
        researchProduct: "",
        researchSubType: "",
        status: "",
        support: "",
        surveyId: "",
        isSignatureRequired: false,
        approachingCount: "",
        laggingCount: "",
        delayedCount: "",
        notHappenedCount: ""
      },
      researchTypeItems: [
        { text: "Girişimsel", value: "Girişimsel" },
        { text: "Gözlemsel", value: "Gözlemsel" },
        {
          text: "Gözlemsel(Kayıt Çalışması)",
          value: "Gözlemsel(Kayıt Çalışması)",
        },
        { text: "Genişletilmiş Erişim", value: "Genişletilmiş Erişim" },
      ],
      researchProductItems: [
        { text: "İlaç", value: "İlaç" },
        { text: "Tıbbi Cihaz", value: "Tıbbi Cihaz" },
        { text: "Aşı", value: "Aşı" },
        { text: "İlaç Dışı", value: "İlaç Dışı" },
      ],
      researchSubTypeItems: [
        {
          text: "PILOT Klinik Araştırrma(CE'siz)",
          value: "PILOT Klinik Araştırrma(CE'siz)",
        },
        {
          text: "PIVOTAL Klinik Araştırrma(CE'siz)",
          value: "PIVOTAL Klinik Araştırrma(CE'siz)",
        },
        {
          text: "Klinik Araştırrma(CE'li)",
          value: "Klinik Araştırrma(CE'li)",
        },
        { text: "Faz 0", value: "Faz 0" },
        { text: "Faz 1", value: "Faz 1" },
        { text: "Faz 2", value: "Faz 2" },
        { text: "Faz 3", value: "Faz 3" },
        { text: "Faz 4", value: "Faz 4" },
        { text: "BY/BE Çalışmaları", value: "BY/BE Çalışmaları" },
      ],
      statusItems: [
        { text: "Hasta Alımı Başlamadı", value: "Hasta Alımı Başlamadı" },
        { text: "Hasta Alımı Devam Ediyor", value: "Hasta Alımı Devam Ediyor" },
        {
          text: "Hastalar Kayıt Olmaya Davet Edildi",
          value: "Hastalar Kayıt Olmaya Davet Edildi",
        },
        { text: "Çalışma Devam Ediyor", value: "Çalışma Devam Ediyor" },
        { text: "Çalışma Tamamlandı", value: "Çalışma Tamamlandı" },
        { text: "Çalışma İptal Edildi", value: "Çalışma İptal Edildi" },
        {
          text: "Çalışma Geçici Olarak Durduruldu",
          value: "Çalışma Geçici Olarak Durduruldu",
        },
        {
          text: "Çalışma Tamamlanmadan Sonlandırıldı",
          value: "Çalışma Tamamlanmadan Sonlandırıldı",
        },
        { text: "Çalışma Geri Çekildi", value: "Çalışma Geri Çekildi" },
      ],
      fileTableItems: [],
      fileNameTable: [
        {
          text: "Dosya Adı",
          align: "left",
          sortable: false,
          value: "fileName",
        },
        { text: "İndir", align: "right", value: "download", sortable: false },
        { text: "Sil", align: "right", value: "delete", sortable: false },
      ],
      fileNameTable2: [
        {
          text: "Dosya Adı",
          align: "left",
          sortable: false,
          value: "fileName",
        },
        { text: "İndir", align: "right", value: "download", sortable: false },
      ],
      workInfoList: [],
      patientsList: [],
      formData: undefined,
      tempFile: {},
      count: "",
      visitFile: [],
      menu3: false,
      responsibleCroitems: [],
      supportersitem: [],
      bodyTemp: {},
      authorityControl: [],
      selectedPageItemName: "",
      selected3: {
        id: "",
        workid: "",
        name: "",
        surname: "",
        researcherid: "",
        signatureDate: new Date().toISOString().substr(0, 10),
        sessionId:"",
      },
      visitFileTemp: null,
      sak: "",
      addWorkInfo: [],
      patientId: "",
      visitName: "",
      workShortName: "",
      workName: "",
      createDate: "",
      completionDate: "",
      protocolNumber: "",
      patientListShow: [],
      workVisitTable: [],
      msg:this.newmsg,
      loading:this.load
    };
  },
  props: {
    load: {
      type: Boolean,
      default: false,
    },
    newmsg: {
      type: String,
      default: "Dosya Yükleniyor...",
    },
    width: {
      type: Number,
      default: 300,
    },
    color: {
      type: String,
      default: "grey",
    },
    dark: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    barColor: {
      type: String,
      default: "white",
    },
    indeterminate: {
      type: Boolean,
      default: true,
    },
    percentComplete: {
      type: Number,
      default: 100,
    },
  },
  destroyed() {
    this.$store.dispatch(REMOVE_SELECTEDPAGEITEMNAME);
    this.$store.dispatch(REMOVE_FROMWORKLIST);
  },

  mounted() {
    this.workID = window.localStorage.getItem("getWorkID");
    this.selected2.Id = this.workID;
    this.userID = this.currentUser.id;
    //this.userInfoList = this.currentUser;
    this.selected.workid = this.workID;
    this.globaladmin = this.isGlobalAdmin;
    this.cro = this.isCro;
    this.user = this.isNormalUser;
    this.isShowGlobalAdmin = this.globaladmin;
    this.vizitChanged=false;
    this.isShowCro = this.cro;
    this.isShowUser = this.user;
    this.getUserWorkDetails();
    this.getResponsibleResearcher();
    this.getworkmemberList2(this.workID);
    this.getWorkMemberRoleList();
    this.getUserCro();
    this.getSupporters();
    this.getVisitTable();
    this.setVisitHeaders();
    
    //this.setAuthority();
    //   if (this.$store.state.selectedpageitem.fromWorkList == true){
    //   console.log("s",this.$store.state.selectedpageitem.fromWorkList);
    //    this.dialog = true;
    // }
    
  },
  methods: {
    setVisitHeaders(){
      console.log("auth,",this.showAuthority);
      console.log("auth2",this.isShowGlobalAdmin);
      if(this.showAuthority==2||this.isShowGlobalAdmin) {
        this.headersVisitTable.push({ text: "Merkez",align: "center", value: "centerName" },
                                    { text: "Araştırmacı Adı",align: "center", value: "researcherName" },
                                    { text: "Hastaya Git",align: "center", value: "Id", sortable: false });
       }else{
        this.headersVisitTable.push({ text: "Hastaya Git",align: "center", value: "Id", sortable: false });
       }
    },
    getVisitTable() {
      ApiService.get("followvisit/" + this.workID)
        .then(({ data }) => {
          console.log("visit takplistesi : ", data);
          this.workVisitTable = data.result;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    turnBack() {
      this.dialogiframe = false;
      this.$router.push({
        name: "patientdetails",
      });
    },
    closeDialog() {
      this.$store.dispatch(REMOVE_FROMWORKLIST);
      this.dialog = false;
    },
    downloadIt(item) {
      console.log("file bilgileri", item);
      return axios
        .get("/downloadfile/" + item.id, { responseType: "blob" })
        .then(({ data }) => {
          // console.log("response",response);
          const url = window.URL.createObjectURL(new Blob([data]));
          console.log("url",url);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.fileName);
          link.click();
          Swal.fire({
            icon: "success",
            title: "Dosya İndirildi",
            showConfirmButton: false,
            timer: 1200,updatePatient
          });
        })
        .catch((err) => {
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updatePatient(item) {
      ApiService.get("patient", item.id)
        .then(({ data }) => {
          if(data.result.patientVisits[0].surveyUrl!=null){
            let result = data.result.patientVisits[0].surveyUrl.includes("?snc=")
              if(result){
                const urlArray = data.result.patientVisits[0].surveyUrl.split("?snc=");
                this.selected3.sessionId=urlArray[1].split("&")[0];
              }
          }else{
            this.selected3.sessionId="Session Id Oluşturulmadı."
          }
         
          this.dialog3 = true;
        (this.selected3.name = item.name.charAt(0)),
        (this.selected3.surname = item.name.charAt(1)),
        (this.selected3.researcherid = item.researcherId);
        this.selected3.SignatureDate = item.signatureDate;
        this.selected3.id = item.id;
        this.selected3.workid = item.workId;
        console.log("url:",this.selected3.sessionId);
        console.log("url:",data);
        })
        .catch((err) => {
          console.log("error", err);
        });

      
    },
    openDeleteUserDialog(patientId){
      this.dialog3 = false;
      Swal.fire({
            icon:"info",
            title: "Hasta bilgileri sistemden kaldırılacak. Emin misiniz?",
            showCancelButton: true,
            showConfirmButton: true,
            closeOnConfirm: true,
            confirmButtonText: "SİL",
            cancelButtonText: "İPTAL",
            closeOnCancel: false,
          }).then(function(isConfirm) {
      if (isConfirm && isConfirm.value == true) {
        ApiService.delete("patient/"+patientId)
          .then(({data})=>{
            
            if(data.status=="True"){
              
            Swal.fire({
            icon: "success",
            title: "Hasta Silindi",
            showConfirmButton: false,
            timer: 1200,
          });
          location.reload();
        }else{
          Swal.fire({
            icon: "error",
            title: "Hasta Silinirken Bir Sorun Yaşandı.",
            showConfirmButton: false,
            timer: 1200,
          });
        }
          });
      }
    });
    },
    dialogClose(index){
      console.log(index);
      index=false;
      console.log(index);
    },
    updatePatientInfo() {
      let body = {
        id: this.selected3.id,
        workid: this.selected3.workid,
        researcherId: this.selected3.researcherid,
        name: this.selected3.name,
        surname: this.selected3.surname,
        signatureDate: this.selected3.SignatureDate,
      };
      let body2={
        patientId: this.selected3.id,
        sessionId: this.selected3.sessionId
      }
      if(this.isShowGlobalAdmin){
        console.log("admin user");
      ApiService.put("updatesession",body2)
        .then(({data})=>{
          console.log("gelen data:",data);
          ApiService.post("updatePatient", body)
        .then(({ data }) => {
          console.log("servis result geldi ", data);
          Swal.fire({
            icon: "success",
            title: "Hasta Bilgileri Güncellendi",
            showConfirmButton: false,
            timer: 1200,
          });
          this.patientListShow = [];
          this.getUserWorkDetails();
          this.dialog3 = false;
        })
        .catch((err) => {
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
        });
      }else{
        console.log("normal user");
        ApiService.post("updatePatient", body)
        .then(({ data }) => {
          console.log("servis result geldi ", data);
          Swal.fire({
            icon: "success",
            title: "Hasta Bilgileri Güncellendi",
            showConfirmButton: false,
            timer: 1200,
          });
          this.patientListShow = [];
          this.getUserWorkDetails();
          this.dialog3 = false;
        })
        .catch((err) => {
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
      }
    },
    getDateFormat(item) {
      let date = item ? item.split("T")[0] : "-";
      // console.log("date",date);
      let date2 = new Date(date).toLocaleString("tr");
      //console.log("date",date2);
      let date3 = date2 ? date2.split(" ")[0] : "-";

      return date3;
    },
    getSupporters() {
      ApiService.get("supporters")
        .then(({ data }) => {
          //console.log("Supporters geldi ", data);
          this.supportersitem = data.result;
          // console.log("this supportesitems", this.supportersitem);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getUserCro() {
      ApiService.get("usercro")
        .then(({ data }) => {
          //console.log("Crolar geldi ", data);
          // this.responsibleCroitems.push({
          //   text: data.result,
          //   value: data.result,
          // });
          this.responsibleCroitems = data.result;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    changeResearchSubType() {
      this.researchSubTypeItems = [];
      // console.log("here", this.selected.researchProduct);
      if (this.selected.researchProduct == "Tıbbi Cihaz") {
        this.researchSubTypeItems.push(
          {
            text: "PILOT Klinik Araştırrma(CE'siz)",
            value: "PILOT Klinik Araştırrma(CE'siz)",
          },
          {
            text: "PIVOTAL Klinik Araştırrma(CE'siz)",
            value: "PIVOTAL Klinik Araştırrma(CE'siz)",
          },
          {
            text: "Klinik Araştırrma(CE'li)",
            value: "Klinik Araştırrma(CE'li)",
          }
        );
      } else {
        this.researchSubTypeItems.push(
          { text: "Faz 0", value: "Faz 0" },
          { text: "Faz 1", value: "Faz 1" },
          { text: "Faz 2", value: "Faz 2" },
          { text: "Faz 3", value: "Faz 3" },
          { text: "Faz 4", value: "Faz 4" },
          { text: "BY/BE Çalışmaları", value: "BY/BE Çalışmaları" }
        );
      }
    },
    abc(ref, item) {
      // let b = "element" + item.id;
      //console.log(this.$refs);
      //console.log(b);
      // console.log(this.$refs[b]);
      this.$refs[ref][0].save(item.plannedDate);
    },
    getworkmemberList2(workid) {
      ApiService.get("/users" + "?croDetail=" + true + "&workId=" + workid)
        .then(({ data }) => {
          //console.log("workmember geldi", data.result);
          this.getworkmemberList = data.result;
          //this.desserts2 =this.getworkmemberList;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getWorkMemberRoleList() {
      ApiService.get("workmemberroles")
        .then(({ data }) => {
          //console.log("workmemberRolleri geldi", data.result);
          this.getworkmemberRole = data.result;
          //this.desserts2 =this.getworkmemberList;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    addWorkMember() {
      //console.log("calismaid", this.calismaid);
      let body = {
        workid: this.workID,
        userid: this.search,
        roleId: this.roleid,
      };
      if (this.search == null && this.roleid != "") {
        return Swal.fire({
          title: "",
          text: "Lütfen Kullanıcı  Seçiniz",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      } else if (this.roleid == undefined && this.search != null) {
        return Swal.fire({
          title: "",
          text: "Lütfen Kullanıcının Rolünü  Seçiniz",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      } else if (this.search == null && this.roleid == "") {
        return Swal.fire({
          title: "",
          text: "Lütfen Kullanıcı ve  Rol  Seçiniz",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      } else {
        console.log("giden body", body);
        ApiService.post("/workmember", body)
          .then(({ data }) => {
            console.log("Calisma ekibi ekle dönen data", data);
            this.desserts2.push(data.result);
            // console.log("dessert2", this.desserts2);
            Swal.fire({
              icon: "success",
              title: "Kullanıcı Çalışmaya Eklendi",
              showConfirmButton: false,
              timer: 1200,
            });
            this.getResponsibleResearcher();
          })
          .catch((err) => {
            Swal.fire({
              title: "",
              text: err.response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            console.log("error", err);
          });
      }
    },
    createVisitDetails() {
      this.visitFileTemp=this.visitFileTemp==null?0:this.visitFileTemp;
      var toplam = parseInt(this.count) + parseInt(this.visitFileTemp);
      var indexStart = this.visitFileTemp + 1;
      for (let index = indexStart; index <= toplam; index++) {
        let obje = {
          visitName: "",
          pageId: "",
          plannedDate: new Date().toISOString().substr(0, 10),
          menu3: false,
          dateRef: null,
          workId: this.workID,
          id: index,
        };
        this.visitFile.push(obje);
      }
      this.vizitChanged=true;
      //console.log("array burada", this.visitFile);
    },
    deleteVisit(index) {
      if (
        this.visitFile[index].visitName == "" &&
        this.visitFile[index].pageId == ""
      ) {
        this.visitFile.splice(index, 1);
      } else if (
        this.visitFile[index].visitName != "" &&
        this.visitFile[index].pageId == ""
      ) {
        this.visitFile.splice(index, 1);
      } else if (
        this.visitFile[index].visitName == "" &&
        this.visitFile[index].pageId != ""
      ) {
        this.visitFile.splice(index, 1);
      } else {
        if (index + 1 > this.visitFileTemp) {
          this.visitFile.splice(index, 1);
        } else {
          ApiService.delete("visit?id=" + this.visitFile[index].id)
            .then(({ data }) => {
              console.log("Silindi", data);

              Swal.fire({
                icon: "success",
                title: "Vizit Silindi",
                showConfirmButton: false,
                timer: 1200,
              });
              this.getUserWorkDetails();
            })
            .catch((err) => {
              Swal.fire({
                title: "",
                text: err.response.data.message,
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            });
        }
      }
    },
    addVisit() {
      document.getElementById('btnSave').disabled = true;
      this.msg = "Vizit Ekleniyor...";
      this.loading = true;
      let sendArray = [];
      //let sendArray = [];
      for (let index = 0; index < this.visitFile.length; index++) {
        if (
          this.visitFile[index].id == null ||
          this.visitFile[index].menu3 != null
        ) {
          let body = {
            visitName: this.visitFile[index].visitName,
            pageId: this.visitFile[index].pageId,
            plannedDate: this.visitFile[index].plannedDate,
            workId: this.workID,
          };
          sendArray.push(body);
        }
      }
      console.log("visit file id bos", sendArray);
      ApiService.post("visits", sendArray)
        .then(({ data }) => {
          console.log("Visit oluştu geldi ", data);
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: "Vizit Eklendi",
            showConfirmButton: false,
            timer: 1200,
          });
          document.getElementById('btnSave').disabled = false;
          this.getUserWorkDetails();
        })
        .catch((err) => {
          document.getElementById('btnSave').disabled = false;
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateVisit() {
      document.getElementById('btnUpdate').disabled = true;
      this.msg = "Vizit Güncelleniyor...";
      this.loading = true;
      let sendArray = [];
      console.log("visitss",this.visitFile);
      for (let index = 0; index < this.visitFile.length; index++) {
        
          let body = {
            visitName: this.visitFile[index].visitName,
            pageId: this.visitFile[index].pageId,
            plannedDate: this.visitFile[index].plannedDate,
            workId: this.workID,
            id:this.visitFile[index].id
          };
          
          sendArray.push(body);
        
      }
      ApiService.post("visits", sendArray)
        .then(({ data }) => {
          console.log("Visit oluştu geldi ", data);
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: "Vizitler Güncellendi",
            showConfirmButton: false,
            timer: 1200,
          });
          document.getElementById('btnUpdate').disabled = false;
          this.getUserWorkDetails();
        })
        .catch((err) => {
          document.getElementById('btnUpdate').disabled = false;
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getPatient(id) {
      this.patientId = id;
      ApiService.get("patient", id)
        .then(({ data }) => {
          console.log("Hasta bilgileri geldi ", data);
          this.loading = false;
          window.localStorage.setItem("getPatientId", data.result.id);
          // this.$router.push({
          //   name: "patientdetails",
          //   params: { surveyId: data.result.patientVisits[0].surveyUrl },
          // });
          this.iframeLink = data.result.patientVisits[0].surveyUrl;
          this.visitName = data.result.patientVisits[0].visitName;
          this.dialogiframe = false;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    createPatient() {
      document.getElementById('btncreatePatient').disabled = true;
      this.dialog = false;
      this.msg = "Lütfen Bekleyiniz...";
      this.loading = true;
      let body = this.selected;
      console.log("servis result geldi ", body);
      ApiService.post("patient", body)
        .then(({ data }) => {
          console.log("servis result geldi ", data);
          this.getPatient(data.result);
          Swal.fire({
            icon: "success",
            title: "Hasta Eklendi",
            showConfirmButton: false,
            timer: 1200,
          });
          this.patientListShow=[];
          this.getUserWorkDetails();
        })
        .catch((err) => {
          document.getElementById('createPatient').disabled = false;
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
        this.loading = false;
    },
    getUserWorkDetails() {
      ApiService.get("workget", this.workID+"/20"+"/1")
        .then(({ data }) => {
          console.log("Çalışmalar geldi", data.result);
          console.log("workget", this.workID+"/20"+"/1");
          this.$store.dispatch(
            SET_SELECTEDPAGEITEMNAME,
            data.result.workShortName
          );
          this.selectedPageItemName =
            this.$store.state.selectedpageitem.selectedPageItemName;
          console.log("this.selectedpageitemname", this.selectedPageItemName);
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Çalışma Listesi", route: "/workslist" },
            { title: this.selectedPageItemName },
          ]);
          this.selected2 = data.result;
          this.sak = data.result.responsibleCro;
          this.workShortName = data.result.workShortName;
          this.workName = data.result.workName;
          this.createDate = data.result.createDate;
          this.completionDate = data.result.completionDate;
          this.protocolNumber = data.result.protocolNumber;
          this.workInfoList = data.result;
          this.addWorkInfo = data.addResult;
          this.patientsList=[];
          this.patientsList = data.result.patients;
          console.log("patientlist", this.patientsList);
          this.selected2.Id = data.result.workID;
          this.selected2.responsibleCro = data.addResult.croId;

          for (let index = 0; index < this.patientsList.length; index++) {
            this.patientListShow.push({
              createDate: this.patientsList[index].createDate,
              deleteDate: this.patientsList[index].deleteDate,
              id: this.patientsList[index].id,
              name:
                this.patientsList[index].name +
                this.patientsList[index].surname,
              patientNo: this.patientsList[index].patientNo,
              patientResearchers: this.patientsList[index].patientResearchers,
              fullName: this.patientsList[index].researcher.name +" "+ this.patientsList[index].researcher.surname,
              researcherInfo: this.patientsList[index].researcher.name +" "+ this.patientsList[index].researcher.surname +" \n"+ this.patientsList[index].researcher.workMembers.length==0?this.patientsList[index].researcher.workMembers[0].role:"",
              researcherRole:this.patientsList[index].researcher.workMembers[0]!=null?this.patientsList[index].researcher.workMembers[0].role:"",
              patientVisits: this.patientsList[index].patientVisits,
              reasonForIncompletion:
                this.patientsList[index].reasonForIncompletion,
              researcher: this.patientsList[index].researcher,
              researcherId: this.patientsList[index].researcherId,
              signature: this.patientsList[index].signature,
              signatureDate: this.patientsList[index].signatureDate,
              status: this.patientsList[index].status,
              workId: this.patientsList[index].workId,
              lock: this.patientsList[index].lock,
            });
          }
          console.log("yeni hasta listesi", data.result);
          this.visitFile = data.result.visits;
          this.visitFileTemp = data.result.visits.length;
          this.fileTableItems = data.result.files;
          this.desserts2 = data.result.workMembers;
          this.authorityControl = data.result.workMembers;
          for (let index = 0; index < this.authorityControl.length; index++) {
            if (this.authorityControl[index].userId == this.userID) {
              this.showAuthority =
                this.authorityControl[index].authorizationGroup;
            }
          }
          // for (let index = 0; index < this.visitFile.length; index++) {
          //   this.visitFile[index].plannedDate=this.getDateFormat(this.visitFile[index].plannedDate);

          // }
          
        })
        .then(() => {
          ApiService.get("work", this.workID)
        .then(({ data }) => {
          console.log("Çalışmalar geldi", data.result);
          this.$store.dispatch(
            SET_SELECTEDPAGEITEMNAME,
            data.result.workShortName
          );
          this.selectedPageItemName =
            this.$store.state.selectedpageitem.selectedPageItemName;
          console.log("this.selectedpageitemname", this.selectedPageItemName);
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Çalışma Listesi", route: "/workslist" },
            { title: this.selectedPageItemName },
          ]);
          this.sak = data.result.responsibleCro;
          this.workShortName = data.result.workShortName;
          this.workName = data.result.workName;
          this.createDate = data.result.createDate;
          this.completionDate = data.result.completionDate;
          this.protocolNumber = data.result.protocolNumber;
          this.workInfoList = data.result;
          this.addWorkInfo = data.addResult;
          this.patientsList = data.result.patients.slice(20);
          console.log("patientlist", this.patientsList);
          for (let index = 0; index < this.patientsList.length; index++) {
            this.patientListShow.push({
              createDate: this.patientsList[index].createDate,
              deleteDate: this.patientsList[index].deleteDate,
              id: this.patientsList[index].id,
              name:
                this.patientsList[index].name +
                this.patientsList[index].surname,
              patientNo: this.patientsList[index].patientNo,
              patientResearchers: this.patientsList[index].patientResearchers,
              patientVisits: this.patientsList[index].patientVisits,
              reasonForIncompletion:
                this.patientsList[index].reasonForIncompletion,
              researcher: this.patientsList[index].researcher,
              fullName: this.patientsList[index].researcher.name +" "+ this.patientsList[index].researcher.surname,
              researcherInfo: this.patientsList[index].researcher!=null?this.patientsList[index].researcher.name +" "+ this.patientsList[index].researcher.surname +" \n"+ this.patientsList[index].researcher.workMembers[0].role:"",
              researcherId: this.patientsList[index].researcherId,
              signature: this.patientsList[index].signature,
              signatureDate: this.patientsList[index].signatureDate,
              status: this.patientsList[index].status,
              workId: this.patientsList[index].workId,
              lock: this.patientsList[index].lock,
            });
          }
          console.log("yeni hasta listesi", data.result);
          this.visitFile = data.result.visits;
          this.visitFileTemp = data.result.visits.length;
          this.fileTableItems = data.result.files;
          this.desserts2 = data.result.workMembers;
          this.authorityControl = data.result.workMembers;
          for (let index = 0; index < this.authorityControl.length; index++) {
            if (this.authorityControl[index].userId == this.userID) {
              this.showAuthority =
                this.authorityControl[index].authorizationGroup;
            }
          }
          // for (let index = 0; index < this.visitFile.length; index++) {
          //   this.visitFile[index].plannedDate=this.getDateFormat(this.visitFile[index].plannedDate);

          // }
          this.selected2 = data.result;
          this.selected2.Id = data.result.workId;
          this.selected2.responsibleCro = data.addResult.croId;
        })})
        .catch((err) => {
          console.log("error", err);
        });
    },
    getResponsibleResearcher() {
      ApiService.get("work/" + this.workID + "/researchers")
        .then(({ data }) => {
          //console.log("Sorumlu Araştırmacılar geldi", data.result);
          this.userInfoList = data.result;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    addFileToTable() {
      console.log("file ismi", this.tempFile);
      if (this.tempFile.size == undefined) {
        Swal.fire({
          title: "",
          text: "Lütfen bir dosya seçiniz.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      } else {
        //this.fileTableItems.push(this.tempFile);
        this.uploadfile();
      }
    },
    changeName(text){
      console.log("changeName",this.formData);
      this.formData.set('FileName', text);
    },
    changeFile(file) {
      console.log("name",file);
      if (file.size > 10000000) {
        alert("Lütfen 10 MB dan az dosya yükleyiniz.");
        this.$refs.fileupload.value = null;
      } else {
        let formData = new FormData();
        formData.append("File", file);
        formData.append("FileName", this.fileNameSend);
        formData.append("WorkId", this.workID);
        this.formData = formData;
        console.log("file ismi", this.$refs.fileupload);
        console.log("file ismi", this.formData);
        //console.log("file ismi", file.name);
        let obje = { name: this.fileNameSend, size: file.size, id: "" };
        this.tempFile = obje;
        //console.log("tempfile0", this.array);
      }
    },
    deleteItemFromFileTable(item) {
      ApiService.delete("/file" + "?id=" + item.id)
        .then(({ data }) => {
          console.log("Dosya silindi", data);
          for (var i = 0; i < this.array.length; i++) {
            if (this.array[i].id === item.id) {
              this.array.splice(i, 1);
            }
          }
          Swal.fire({
            title: "",
            text: "Dosya Silindi",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getUserWorkDetails();
        })
        .catch((err) => {
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    uploadfile() {
      console.log("formdata", this.formData);
      this.msg = "Dosya Yükleniyor...";
      this.loading = true;
      console.log("giden", this.formData);
      ApiService.post("/file", this.formData)
        .then(({ data }) => {
          console.log("yükleme dosyaları geldi", data);
          this.fileTableItems.push(data.result);
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Dosya Eklendi",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getUserWorkDetails();
        })
        .catch((err) => {
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    // deleteFile(item) {
    //   //console.log("delete item", item);

    // },
    getPatientId(item) {
      console.log("hasta id",item);
      window.localStorage.setItem("getPatientId", item.id);
    },
    getCurrentid(item) {
      // console.log("indexi arıyoruz", item.userId);
      this.$router.push({ name: "usersview", params: { itemid: item.userId } });
      let show = false;
      window.localStorage.setItem("userIDFromWorks", show);
    },
    deleteMember(item) {
      //console.log("item silnidi", item.id);
      ApiService.delete("workmember?id=" + item.id)
        .then(({ data }) => {
          console.log("Data silindi", data);
          Swal.fire({
            title: "",
            text: "Kullanıcı Çalışmadan Silindi",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getUserWorkDetails();
        })
        .catch((err) => {
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateWorkDetails() {
      let body = this.selected2;
      console.log("gönderilen data", body);
      ApiService.put("work", body)
        .then(({ data }) => {
          console.log("Calisma güncellendi", data);
          Swal.fire({
            icon: "success",
            title: "Çalışma Detayı Güncellendi",
            showConfirmButton: false,
            timer: 1200,
          });
          this.getUserWorkDetails();
        })
        .catch((err) => {
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          console.log("error", err);
        });
    },
    updateRole() {
      let body = {
        roleId: this.roleidUpdate,
        workId: this.bodyTemp.workId,
        id: this.bodyTemp.id,
      };

      ApiService.put("workmember", body)
        .then(({ data }) => {
          console.log("Role güncellendi", data);
          this.dialog2 = false;
          Swal.fire({
            icon: "success",
            title: "Rol Güncellendi",
            showConfirmButton: false,
            timer: 1200,
          });
          this.getUserWorkDetails();
        })
        .catch((err) => {
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          console.log("error", err);
        });
    },
    getRoleUpdateInfo(item) {
      this.dialog2 = true;

      this.bodyTemp = {
        role: this.roleidUpdate,
        workId: item.workId,
        id: item.id,
      };
    },
    // setAuthority(){
    //           console.log("this.userid",this.userID);
    //           console.log("this.authorty",this.au);

    //      console.log("show authority ",this.showAuthority);
    // }
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
    ...mapGetters([
      "currentUserPersonalInfo",
      "currentUser",
      "isGlobalAdmin",
      "isCro",
      "isNormalUser",
    ]),

  },
};
</script>
